import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { PopupDto, PopupSliceType, PopupType } from '../../types/popup-type';
import { ReduxState } from '../store';

const initialState: PopupSliceType = {
  popups: [],
};

const popupSlice = createSlice({
  name: 'popupInfo',
  initialState,

  reducers: {
    showPopup: (state, action: PayloadAction<PopupDto>) => {
      state.popups = [...state.popups, action.payload];
    },
    showErrorPopup: (state, action: PayloadAction<string>) => {
      state.popups = [
        ...state.popups,
        { type: PopupType.ERROR, message: action.payload },
      ];
    },
    hidePopup: (state) => {
      state.popups.shift();
      state.popups = [...state.popups];
    },
    hideAllPopups: (state) => {
      state.popups = [];
    },
  },
});

export const { showPopup, showErrorPopup, hidePopup, hideAllPopups } =
  popupSlice.actions;

export const selectPopup = (state: ReduxState) =>
  _.first(state.popupInfo.popups);

export default popupSlice;
