import { Crisp } from 'crisp-sdk-web';
import { useCallback } from 'react';

export function useCrisp() {
  const sendChat = useCallback((message: string) => {
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open();
    }
    Crisp.message.sendText(message);
  }, []);

  const showChat = useCallback((message: string, openChat?: boolean) => {
    if (openChat && !Crisp.chat.isChatOpened()) {
      Crisp.chat.open();
    }
    Crisp.message.showText(message);
  }, []);

  const openChat = useCallback(() => {
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open();
    }
  }, []);

  return { sendChat, showChat, openChat };
}
