import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode | undefined;
}

export default function GraphQLProvider({ children }: Props) {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: '/api/graphql',
      credentials: 'include',
      fetch,
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
