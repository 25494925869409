import { ImageLoaderProps } from 'next/image';
import { joinUrl } from './join-url';
import getConfig from 'next/config';

export default function googleCloudStorageImageLoader({
  src,
}: ImageLoaderProps) {
  const { publicRuntimeConfig } = getConfig();
  if (!src.startsWith('https://') && publicRuntimeConfig.CDN_URL) {
    const url = new URL(
      `${publicRuntimeConfig.CDN_URL}${joinUrl(
        'public',
        publicRuntimeConfig.PUBLIC_ASSET_VERSION,
        src
      )}`
    );
    return url.href;
  }
  return `${src}?ts=${new Date().getTime()}`;
}
