import {
  BookingPaidStatus,
  BookingStatus,
  DISCOUNT_TYPE,
  MeetyTimeUnit,
  ServiceBookingTypeEnum,
  SubscriptionContractStatus,
  TabBookingIndex,
  TabBundleIndex,
  TabDepositIndex,
  TabIndex,
  TabSubscriptionIndex,
  TabWaitlistIndex,
  WaitlistStatus,
} from './enums';

export enum LocationType {
  None = 0,
  Address = 1,
  PhoneNumber = 2,
  CustomLink = 3,
  ZoomLinkEachService = 4,
  ZoomLinkEachSlot = 5,
  ZoomLinkEachSlotAndStaff = 6,
  TeamsLinkEachService = 7,
  TeamsLinkEachSlot = 8,
  TeamsLinkEachSlotAndStaff = 9,
}

export const LOCATIONS = [
  {
    value: LocationType.None,
    label: 'ServicesManage.none',
  },
  {
    value: LocationType.Address,
    label: 'ServicesManage.address',
  },
  {
    value: LocationType.PhoneNumber,
    label: 'ServicesManage.phoneNumber',
  },
  {
    value: LocationType.CustomLink,
    label: 'ServicesManage.customeMtgLink',
  },
  {
    value: LocationType.ZoomLinkEachService,
    label: 'ServicesManage.zoomLinkEachService',
  },
  {
    value: LocationType.ZoomLinkEachSlot,
    label: 'ServicesManage.zoomLinkEachSlot',
  },
  {
    value: LocationType.ZoomLinkEachSlotAndStaff,
    label: 'ServicesManage.zoomLinkEachSlotAndStaff',
  },
  {
    value: LocationType.TeamsLinkEachService,
    label: 'ServicesManage.teamsLinkEachService',
  },
  {
    value: LocationType.TeamsLinkEachSlot,
    label: 'ServicesManage.teamsLinkEachSlot',
  },
  {
    value: LocationType.TeamsLinkEachSlotAndStaff,
    label: 'ServicesManage.teamsLinkEachSlotAndStaff',
  },
];

export const dateTimeConstants = [
  {
    value: `${MeetyTimeUnit.Minute}`,
    label: 'ServicesManage.minutes',
  },
  {
    value: `${MeetyTimeUnit.Hour}`,
    label: 'ServicesManage.hours',
  },
];

export const dateOptions = [
  { value: '1', label: 'SettingNoti.1day' },
  { value: '2', label: 'SettingNoti.2day' },
  { value: '3', label: 'SettingNoti.3day' },
  { value: '4', label: 'SettingNoti.4day' },
  { value: '5', label: 'SettingNoti.5day' },
  { value: '6', label: 'SettingNoti.6day' },
  { value: '7', label: 'SettingNoti.7day' },
];

export const dateTimeConstantsAll = [
  {
    value: `${MeetyTimeUnit.Minute}`,
    label: 'ServicesManage.minutes',
  },
  {
    value: `${MeetyTimeUnit.Hour}`,
    label: 'ServicesManage.hours',
  },
  {
    value: `${MeetyTimeUnit.Day}`,
    label: 'ServicesManage.days',
  },
  {
    value: `${MeetyTimeUnit.Week}`,
    label: 'ServicesManage.weeks',
  },
  {
    value: `${MeetyTimeUnit.Month}`,
    label: 'ServicesManage.months',
  },
];
export const dateTimeConstantsDayAndMore = [
  {
    value: `${MeetyTimeUnit.Day}`,
    label: 'ServicesManage.days',
  },
  {
    value: `${MeetyTimeUnit.Week}`,
    label: 'ServicesManage.weeks',
  },
  {
    value: `${MeetyTimeUnit.Month}`,
    label: 'ServicesManage.months',
  },
];

export const TYPE_OF_BOOKINGS = [
  {
    value: ServiceBookingTypeEnum.Regular,
    label: 'ServicesManage.standardBooking',
    enable: true,
  },
  {
    value: ServiceBookingTypeEnum.Bundle,
    label: 'ServicesManage.multipleDayBooking',
    enable: true,
  },
  // {
  //   value: BookingTypeEnum.DayBasedBooking,
  //   label: 'ServicesManage.daysBooking',
  //   enable: ENABLE_DAYS_BOOKING,
  // },
  // {
  //   value: ServiceBookingTypeEnum.Instant,
  //   label: 'ServicesManage.instantBooking',
  //   enable: true,
  // },
];

/*
Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Yearly = 4,
  Hourly = 8,*/
export const SubsDurationUnitOptions = [
  {
    value: 8,
    label: 'ServicesManage.hours',
  },
  {
    value: 0,
    label: 'ServicesManage.days',
  },
  {
    value: 1,
    label: 'ServicesManage.weeks',
  },
  {
    value: 2,
    label: 'ServicesManage.months',
  },
  {
    value: 4,
    label: 'ServicesManage.years',
  },
];

export const navigationLinksConstants = [
  {
    label: 'Common.NavigationMenu.services',
    destination: '/',
    rel: 'home',
  },
  {
    label: 'Common.NavigationMenu.services',
    destination: '/services',
  },
  {
    label: 'Common.NavigationMenu.bookings',
    destination: '/bookings',
  },
  {
    label: 'Common.NavigationMenu.team',
    destination: '/staff',
  },
  {
    label: 'Common.NavigationMenu.calendar',
    destination: '/calendar',
  },
  {
    label: 'Common.NavigationMenu.settings',
    destination: '/shops',
  },
  {
    label: 'Common.NavigationMenu.pricing',
    destination: '/pricing',
  },
];

export const statusPaidConstants = [
  {
    value: BookingPaidStatus.Paid,
    label: 'Bookings.paid',
  },
  {
    value: BookingPaidStatus.UnPaid,
    label: 'Bookings.unpaid',
  },
  {
    value: BookingPaidStatus.PartiallyPaid,
    label: 'Bookings.partiallypaid',
  },
  {
    value: BookingPaidStatus.Pending,
    label: 'Bookings.pending',
  },
  // {
  //   value: 4,
  //   label: 'Bookings.expired',
  // },
];

export const bookingStatusConstants = [
  {
    value: 0,
    label: 'Bookings.allBookings',
  },
  {
    value: 1,
    label: 'Bookings.upcoming',
  },
  {
    value: 2,
    label: 'Bookings.past',
  },
  {
    value: 4,
    label: 'Bookings.cancelled',
  },
];

export const statusBookingConstants = [
  {
    label: 'Bookings.pending',
    value: BookingStatus.Pending,
  },
  {
    label: 'Bookings.rejected',
    value: BookingStatus.Rejected,
  },
  {
    label: 'Bookings.approved',
    value: BookingStatus.Active,
  },
  {
    label: 'Bookings.noShow',
    value: BookingStatus.NoShow,
  },
  {
    label: 'Bookings.arrived',
    value: BookingStatus.Arrived,
  },
  {
    label: 'Bookings.completed',
    value: BookingStatus.Completed,
  },
  {
    label: 'Bookings.canceled',
    value: BookingStatus.Canceled,
  },
  {
    label: 'Bookings.deleted',
    value: BookingStatus.DELETED,
  },
  {
    label: 'Bookings.canceling',
    value: BookingStatus.InProgressCancel,
  },
];

export const statusBundleConstants = [
  {
    label: 'Bookings.rejected',
    value: BookingStatus.Rejected,
  },
  {
    label: 'Bookings.approved',
    value: BookingStatus.Active,
  },
  {
    label: 'Bookings.completed',
    value: BookingStatus.Completed,
  },
];

export const statusSubsBookingConstants = [
  {
    label: 'SubscriptionPlan.paused',
    value: SubscriptionContractStatus.PAUSED,
  },
  {
    label: 'SubscriptionPlan.cancelled',
    value: SubscriptionContractStatus.CANCELLED,
  },
  {
    label: 'SubscriptionPlan.active',
    value: SubscriptionContractStatus.ACTIVE,
  },
  {
    label: 'Dashboard.expired',
    value: SubscriptionContractStatus.EXPIRED,
  },
];
export const frequencyOptionsConstants = [
  { label: 'SubscriptionPlan.dayS', value: 1 },
  { label: 'SubscriptionPlan.weekS', value: 2 },
  { label: 'SubscriptionPlan.monthS', value: 3 },
  { label: 'SubscriptionPlan.yearS', value: 4 },
];

export const discountTypeOptionsConstants = [
  {
    label: 'SubscriptionPlan.percentOff',
    value: DISCOUNT_TYPE.percent,
  },
  {
    label: 'SubscriptionPlan.amountOff',
    value: DISCOUNT_TYPE.amount,
  },
  {
    label: 'SubscriptionPlan.fixedPrice',
    value: DISCOUNT_TYPE.fixed,
  },
];

export const monthsOptionsConstants = [
  { label: 'SubscriptionPlan.january', value: 1 },
  { label: 'SubscriptionPlan.february', value: 2 },
  { label: 'SubscriptionPlan.march', value: 3 },
  { label: 'SubscriptionPlan.april', value: 4 },
  { label: 'SubscriptionPlan.may', value: 5 },
  { label: 'SubscriptionPlan.june', value: 6 },
  { label: 'SubscriptionPlan.july', value: 7 },
  { label: 'SubscriptionPlan.august', value: 8 },
  { label: 'SubscriptionPlan.october', value: 9 },
  { label: 'SubscriptionPlan.november', value: 10 },
  { label: 'SubscriptionPlan.december', value: 11 },
  { label: 'SubscriptionPlan.september', value: 12 },
];

export const planTypesConstants = [
  {
    value: 1,
    // label: 'Percent',
    label: 'Services.deposit.percent',
    prefix: '%',
  },
  {
    value: 2,
    // label: 'Price',
    label: 'Services.deposit.fixedAmount',
    prefix: '$',
  },
];

export const timeOptionsConstants = [
  {
    value: 1,
    label: 'ServicesManage.chargeTime.days',
  },
  {
    value: 2,
    label: 'ServicesManage.chargeTime.hours',
  },
  {
    value: 3,
    label: 'ServicesManage.chargeTime.minutes',
  },
];

export const WaitlistStatusConstants = [
  {
    label: 'WaitList.pending',
    value: WaitlistStatus.PENDING,
  },
  {
    label: 'WaitList.rejected',
    value: WaitlistStatus.REJECTED,
  },
  {
    label: 'WaitList.confirmed',
    value: WaitlistStatus.CONFIRMED,
  },
  {
    label: 'WaitList.expired',
    value: WaitlistStatus.EXPIRED,
  },
];

export const BookingPageTabNames = [
  {
    value: TabIndex.BOOKING,
    label: 'Bookings.booking',
  },
  {
    value: TabIndex.BUNDLE,
    label: 'Bookings.bundle',
  },
  {
    value: TabIndex.SUBSCRIPTION,
    label: 'Dashboard.subscription',
  },
  {
    value: TabIndex.DEPOSIT,
    label: 'Bookings.deposit',
  },
  {
    value: TabIndex.WAITLIST,
    label: 'Bookings.waitlist',
  },
];

export const BookingTabNames = [
  {
    value: TabBookingIndex.UPCOMING,
    label: 'Bookings.upcoming',
  },
  {
    value: TabBookingIndex.PAST,
    label: 'Bookings.past',
  },
  {
    value: TabBookingIndex.CANCELLED,
    label: 'Bookings.cancelled',
  },
];

export const BundleTabNames = [
  {
    value: TabBundleIndex.CURRENT,
    label: 'Bookings.current',
  },
  {
    value: TabBundleIndex.ACTIVE,
    label: 'SubscriptionPlan.active',
  },
  {
    value: TabBundleIndex.COMPLETED,
    label: 'Bookings.completed',
  },
  {
    value: TabBundleIndex.EXPIRED,
    label: 'Bookings.expired',
  },
  {
    value: TabBundleIndex.CANCELLED,
    label: 'Bookings.cancelled',
  },
];

export const SubscriptionTabNames = [
  {
    value: TabSubscriptionIndex.CURRENT,
    label: 'Bookings.current',
  },
  {
    value: TabSubscriptionIndex.ACTIVE,
    label: 'SubscriptionPlan.active',
  },
  {
    value: TabSubscriptionIndex.PAUSED,
    label: 'SubscriptionPlan.paused',
  },
  {
    value: TabSubscriptionIndex.EXPIRED,
    label: 'Bookings.expired',
  },
  {
    value: TabSubscriptionIndex.CANCELLED,
    label: 'Bookings.cancelled',
  },
];

export const DepositTabNames = [
  {
    value: TabDepositIndex.ALL,
    label: 'Bookings.all',
  },
  {
    value: TabDepositIndex.PARTIALLY_PAID,
    label: 'Bookings.partiallypaid',
  },
  {
    value: TabDepositIndex.PENDING_PAYMENT,
    label: 'Bookings.pendingPayment',
  },
  // {
  //   value: TabDepositIndex.UNPAID,
  //   label: 'Bookings.unpaid',
  // },
  {
    value: TabDepositIndex.PAID,
    label: 'Bookings.paid',
  },
];

export const WaitlistTabNames = [
  {
    value: TabWaitlistIndex.ALL,
    label: 'Bookings.all',
  },
  {
    value: TabWaitlistIndex.PENDING,
    label: 'Bookings.pending',
  },
  {
    value: TabWaitlistIndex.CONFIRMED,
    label: 'WaitList.confirmed',
  },
  {
    value: TabWaitlistIndex.REJECTED,
    label: 'Bookings.rejected',
  },
  {
    value: TabWaitlistIndex.EXPIRED,
    label: 'Bookings.expired',
  },
];
