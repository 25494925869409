export default class Clarity {
  static init(id: string): void {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', id);
  }

  static identify(
    userId: string,
    sessionId?: string,
    pageId?: string,
    userHint?: string
  ): void {
    Clarity.invoke('identify', userId, sessionId, pageId, userHint);
    Clarity.invoke('set', 'userId', userId);
  }

  static consent(): void {
    Clarity.invoke('consent');
  }

  private static invoke(...params: unknown[]): void {
    window['clarity'](...params);
  }
}
