import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReduxState } from '../store';
import { NavHistorySliceType } from '../../types/settings-type';

const initialState: NavHistorySliceType = {
  lastAsPath: null,
  testData: 0,
};

const navigationHistorySlice = createSlice({
  name: 'navHistory',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setLastAsPath: (state, action: PayloadAction<{ asPath: string }>) => {
      state.lastAsPath = action.payload.asPath;
      state.testData = 1000;
    },
  },
});

export const { setLastAsPath } = navigationHistorySlice.actions;

export const selectNavHistory = (state: ReduxState) => state.navHistory;

export default navigationHistorySlice;
