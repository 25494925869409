import enPolaris from '@shopify/polaris/locales/en.json';
import en from '../../locales/en.json';

import { AppProvider } from '@shopify/polaris';
import {
  RegisterOptions,
  TranslationDictionary,
  useI18n,
} from '@shopify/react-i18n';
import { PropsWithChildren, memo, useMemo } from 'react';
import { AnalyticEvents } from '../../constants/analytic-events';
import { translateLocale } from '../../utils/helpers';
import { useAnalytics } from './AnalyticsProvider';

function PolarisProvider({
  children,
  translations,
}: PropsWithChildren<{ translations: TranslationDictionary }>) {
  const { trackEvent } = useAnalytics();

  const options: Partial<RegisterOptions> = useMemo(() => {
    return {
      id: 'Translations',
      fallback: { ...en, ...enPolaris, ...translations },
      async translations(locale) {
        const arr = translateLocale(locale);
        const [myTrans, polarisLang] = await Promise.all([
          import(`../../locales/${arr[0]}.json`).catch((ex) => {
            trackEvent(AnalyticEvents.FRONTEND_ERROR, {
              component: 'PolarisProvider-myTrans',
              message: ex.message,
              stack: ex.stack,
            });
            return {};
          }),
          import(`@shopify/polaris/locales/${arr[1]}.json`).catch((ex) => {
            trackEvent(AnalyticEvents.FRONTEND_ERROR, {
              component: 'PolarisProvider-polarisLang',
              message: ex.message,
              stack: ex.stack,
            });
            return {};
          }),
        ]);
        return { ...myTrans, ...polarisLang };
      },
    };
  }, [trackEvent, translations]);

  const [i18n, ShareTranslations] = useI18n(options);

  return (
    <AppProvider i18n={i18n.translations}>
      <ShareTranslations>{children}</ShareTranslations>
    </AppProvider>
  );
}

export default memo(PolarisProvider);
