import { NavMenu } from '@shopify/app-bridge-react';
import { useI18n } from '@shopify/react-i18n';
import getConfig from 'next/config';
import { useCallback, useMemo } from 'react';
import { navigationLinksConstants } from '../../constants/options-const';
import { useDispatch } from 'react-redux';
import navigationHistorySlice from '../../redux/slices/navigationHistorySlice';
import useRouter from '../../hooks/useRouter';

export default function NavigationMenu() {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const router = useRouter();
  const handleClick = useCallback(
    (url: string) => {
      dispatch(
        navigationHistorySlice.actions.setLastAsPath({
          asPath: router.currentPath,
        })
      );
    },
    [dispatch, router.currentPath]
  );

  const navigationLinks = useMemo(() => {
    const { publicRuntimeConfig } = getConfig();
    return [
      ...navigationLinksConstants.map((x) => {
        return { ...x, label: i18n.translate(x.label) };
      }),
      publicRuntimeConfig.DEV_MENU_ENABLED
        ? { destination: '/dev', label: 'Dev' }
        : null,
    ]
      .filter(Boolean)
      .map((item) => {
        return (
          <a
            key={item.destination}
            href={item.destination}
            rel={item.rel}
            onClick={() => handleClick(item.destination)}
          >
            {item.label}
          </a>
        );
      });
  }, [i18n, handleClick]);

  return (
    // This menu is rendered by Shopify App Bridge no matter its visibility.
    // We set display none here to prevent CLS.
    <div style={{ display: 'none' }}>
      <NavMenu>{navigationLinks}</NavMenu>
    </div>
  );
}
