import { I18nContext, I18nManager } from '@shopify/react-i18n';
import { Settings } from 'luxon';
import { PropsWithChildren, memo, useMemo } from 'react';
import { AnalyticEvents } from '../../constants/analytic-events';
import { selectSettings } from '../../redux/slices/settingsSlice';
import { useSelector } from '../../redux/store';
import { translateLocale } from '../../utils/helpers';
import { useAnalytics } from './AnalyticsProvider';

interface Props {
  initLocale: string;
}

function I18nProvider({ initLocale, children }: PropsWithChildren<Props>) {
  const { trackEvent } = useAnalytics();

  const settings = useSelector(selectSettings);

  const locale = useMemo(() => {
    const locale = settings.locale ?? translateLocale(initLocale)[0];
    Settings.defaultLocale = locale;
    return locale;
  }, [initLocale, settings.locale]);

  const i18nManager = useMemo(() => {
    return new I18nManager({
      locale,
      fallbackLocale: locale !== 'en' ? 'en' : undefined, // If locale and fallbackLocale are the same ("en"), we can not change language to this ("en") from other.
      onError(error) {
        trackEvent(AnalyticEvents.FRONTEND_ERROR, {
          component: 'I18nProvider',
          locale: locale,
          message: error.message,
          stack: error.stack,
        });
      },
    });
  }, [locale, trackEvent]);

  return (
    <I18nContext.Provider value={i18nManager}>{children}</I18nContext.Provider>
  );
}

export default memo(I18nProvider);
