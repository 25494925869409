import {
  BlockStack,
  Button,
  InlineStack,
  useBreakpoints,
} from '@shopify/polaris';
import { useMemo } from 'react';
import { BaseProps } from '../../types/settings-type';
import NextImage from '../Image/NextImage';
import styles from './ErrorMessage.module.scss';

interface Props extends BaseProps {
  title: string;
  message: string;
  image: number;
  buttonText?: string;
  onClick?: () => void;
}

export default function ErrorMessage({
  title,
  message,
  image,
  buttonText,
  onClick,
}: Props) {
  const { mdDown } = useBreakpoints();

  const messageMarkup = useMemo(() => {
    return (
      <BlockStack
        gap="400"
        align="center"
        inlineAlign={mdDown ? 'center' : 'start'}
      >
        <div className={styles.title}>{title}</div>
        <div
          className={mdDown ? styles.text_center : styles.text_left}
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        {buttonText && (
          <Button variant="primary" onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </BlockStack>
    );
  }, [buttonText, mdDown, message, onClick, title]);

  const imageMarkup = useMemo(() => {
    return (
      <NextImage
        src={`/image/error/${image}.svg`}
        alt="error"
        width={311}
        height={271}
      />
    );
  }, [image]);

  return (
    <div className={styles.main}>
      {mdDown ? (
        <BlockStack gap="1000" align="space-around">
          {messageMarkup}
          {imageMarkup}
        </BlockStack>
      ) : (
        <InlineStack gap="1000">
          {imageMarkup}
          {messageMarkup}
        </InlineStack>
      )}
    </div>
  );
}
