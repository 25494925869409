function addLeadingSlash(path: string): string {
  return path && typeof path === 'string'
    ? path.charAt(0) !== '/'
      ? '/' + path
      : path
    : '';
}

function stripEndSlash(path: string): string {
  return path[path.length - 1] === '/' ? path.slice(0, path.length - 1) : path;
}

export function joinUrl(...paths: string[]): string {
  return paths
    .filter(Boolean)
    .map((path) => addLeadingSlash(path || '/'))
    .map((path) => (path !== '/' ? stripEndSlash(path) : path))
    .join('');
}
