import { ComplexAction } from '@shopify/polaris';

export enum PopupType {
  INFO = 0,
  WARN = 1,
  ERROR = 2,
}

export interface PopupDto {
  type: PopupType;
  title?: string;
  message?: string;
  actions?: ComplexAction[];
}

export interface PopupSliceType {
  popups: PopupDto[];
}
