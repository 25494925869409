import { useBreakpoints } from '@shopify/polaris';
import { ChatboxColors, ChatboxPosition, Crisp } from 'crisp-sdk-web';
import { DateTime } from 'luxon';
import { memo, useEffect } from 'react';
import { useLazyGetBasicShopQuery } from '../../redux/apis/ShopApi';
import { BaseProps } from '../../types/settings-type';
import { getTimeZoneByOffset } from '../../utils/datetime';

interface Props extends BaseProps {
  websiteId: string;
}

export const AnonymousCrispChat = memo(function AnonymousCrispChat({
  websiteId,
}: Props) {
  useEffect(() => {
    if (!websiteId) {
      return;
    }
    Crisp.configure(websiteId, {
      sessionMerge: false,
    });
    Crisp.setColorTheme(ChatboxColors.Blue);
  }, [websiteId]);

  return null;
});

export const CrispChat = memo(function CrispChat({ websiteId }: Props) {
  const { mdDown } = useBreakpoints();

  const [getShopQuery] = useLazyGetBasicShopQuery();

  useEffect(() => {
    if (!websiteId) {
      return;
    }

    Crisp.configure(websiteId, {
      sessionMerge: false,
      autoload: false,
    });
    Crisp.setColorTheme(ChatboxColors.Blue);

    getShopQuery({}, true)
      .unwrap()
      .then((shop) => {
        const gmtOffset = shop.timeZone.gmtOffset;
        const onboardings = shop.onboardings
          ?.map(({ onboardingTypeId, step }) => {
            return `${onboardingTypeId}-${step}`;
          })
          ?.join(',');
        const installAt = DateTime.fromISO(shop.shopInfo.createdAt, {
          zone: 'utc+7',
          locale: 'en',
        });
        const usedDay =
          Math.floor(
            DateTime.local({ zone: 'utc+7' }).diff(installAt, 'days').days *
              100000
          ) / 100000;

        Crisp.setTokenId(shop.crispIdentity.token);
        Crisp.user.setNickname(shop.shopInfo.name);
        Crisp.user.setEmail(
          shop.crispIdentity.email ?? shop.shopInfo.email,
          shop.crispIdentity.signature
        );
        Crisp.session.setData(
          normalizeData({
            install_at: installAt.toFormat('FFF'),
            used_day: usedDay,
            shop: shop.shopInfo.domain,
            plan: shop.shopInfo.shopifyPlan,
            meetyPlan: shop.currentSubs?.name || '',
            meetyPlanStart: shop.currentSubs?.fromTime
              ? DateTime.fromSeconds(shop.currentSubs?.fromTime, {
                  zone: 'utc+7',
                }).toFormat('FFF')
              : '',
            timezone: getTimeZoneByOffset(gmtOffset, 'GMT'),
            onboardings: onboardings,
          })
        );
      })
      .finally(() => {
        Crisp.load();
      });
  }, [getShopQuery, websiteId]);

  useEffect(() => {
    Crisp.setPosition(mdDown ? ChatboxPosition.Left : ChatboxPosition.Right);
  }, [mdDown]);

  return null;
});

function normalizeData(data: object): object {
  const ba = /^([A-Za-z0-9_\\-]{1,50})$/;

  let normalizedData = null;
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (
        typeof key === 'string' &&
        ba.test(key) &&
        (typeof value === 'string' ||
          typeof value === 'boolean' ||
          (typeof value === 'number' && !isNaN(value)))
      ) {
        if (!normalizedData) {
          normalizedData = {};
        }
        normalizedData[key] = value;
      }
    });
  }
  return normalizedData;
}
