import { useAppBridge } from '@shopify/app-bridge-react';
import { JwtPayload, decode } from 'jsonwebtoken';
import { useRouter as useNextRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from '../components/providers/AnalyticsProvider';
import { AnalyticEvents } from '../constants/analytic-events';
import navigationHistorySlice, {
  selectNavHistory,
} from '../redux/slices/navigationHistorySlice';

const _getUrlPath = (url: string) => {
  if (!url) {
    return url;
  }
  return url.split('?')[0];
};

export default function useRouter() {
  const shopify = useAppBridge();
  const nextRouter = useNextRouter();

  const { trackEvent } = useAnalytics();

  const dispatch = useDispatch();
  const navHistory = useSelector(selectNavHistory);

  const [currentPath, setCurrentPath] = useState(
    _getUrlPath(nextRouter.asPath)
  );
  const onRouteChange = useCallback(() => {
    dispatch(
      navigationHistorySlice.actions.setLastAsPath({
        asPath: nextRouter.asPath,
      })
    );
    shopify.loading(true);
  }, [dispatch, nextRouter.asPath, shopify]);
  const onRouteChangeComplete = useCallback(() => {
    shopify.loading(false);
  }, [shopify]);
  useEffect(() => {
    nextRouter.events?.on('routeChangeStart', onRouteChange);
    nextRouter.events?.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      nextRouter.events?.off('routeChangeStart', onRouteChange);
      nextRouter.events?.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [nextRouter.events, onRouteChange, onRouteChangeComplete]);

  const getUrl = useCallback(
    async (url: string) => {
      const hasSearchParams = url.indexOf('?') >= 0;
      const searchParams = new URLSearchParams();

      [
        'embedded',
        'hmac',
        'host',
        'locale',
        'session',
        'shop',
        'id_token',
      ].forEach((param) => {
        const value = nextRouter.query[param];
        if (value) {
          searchParams.set(
            param,
            Array.isArray(value) ? value.join(',') : value
          );
        } else {
          // console.log('not found:', param, nextRouter.query, nextRouter.asPath);
        }
      });

      const token = await shopify.idToken();
      if (token) {
        searchParams.set('id_token', token);
        if (!searchParams.has('shop')) {
          const decoded = decode(token) as JwtPayload;
          searchParams.set('shop', decoded['dest'].replace('https://', ''));
        }
      }

      return url + (hasSearchParams ? '&' : '?') + searchParams.toString();
    },
    [nextRouter.query, shopify]
  );

  const back = useCallback(() => {
    nextRouter.back();
    trackEvent(AnalyticEvents.PAGE_VIEW, {
      page: navHistory?.lastAsPath,
      type: 'back',
    });
    setCurrentPath(_getUrlPath(navHistory?.lastAsPath));
  }, [navHistory?.lastAsPath, nextRouter, trackEvent]);

  const canBack = useCallback(() => {
    return (
      navHistory?.lastAsPath &&
      _getUrlPath(navHistory?.lastAsPath) != currentPath
    );
  }, [currentPath, navHistory?.lastAsPath]);

  const replace = useCallback(
    async (url: string, shallow?: boolean) => {
      const destination = await getUrl(url);
      nextRouter.replace(destination, destination, { shallow });
      setCurrentPath(_getUrlPath(destination));
      trackEvent(AnalyticEvents.PAGE_VIEW, {
        page: destination,
        type: 'replace',
      });
    },
    [getUrl, nextRouter, trackEvent]
  );

  const push = useCallback(
    async (url: string, shallow?: boolean) => {
      const destination = await getUrl(url);
      nextRouter.push(destination, destination, { shallow });
      setCurrentPath(_getUrlPath(destination));
      trackEvent(AnalyticEvents.PAGE_VIEW, { page: destination, type: 'push' });
    },
    [getUrl, nextRouter, trackEvent]
  );

  return useMemo(() => {
    return {
      pathname: nextRouter.pathname,
      currentPath,
      lastAsPath: _getUrlPath(navHistory?.lastAsPath),
      query: nextRouter.query,
      back,
      canBack,
      replace,
      push,
    };
  }, [
    back,
    nextRouter.pathname,
    currentPath,
    nextRouter.query,
    navHistory?.lastAsPath,
    push,
    canBack,
    replace,
  ]);
}
